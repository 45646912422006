import AppRoutes from "./AppRoutes";
// import Homepage from "./pages/home/Homepage";

function App() {
  return (
    <AppRoutes/>
  );
}

export default App;
