import React, { useState } from 'react';
import './Homepage.css'
import FormModal from '../../components/modal/FormModal'


const Homepage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

    const showPopup = (message) => {
        alert(message);
      };
      return (
        <div className="container">
          {/* Hero Section */}
          <div className="hero">
            <h1>Play on MLB</h1>
            <p>Your Gateway to the Best Sports Activities and Gaming Zone Online.</p>
            <div className="cta-buttons">
              <a                
                className="primary-cta"
                onClick={handleOpenModal}
              >
                Sign Up Now
              </a>
              <a
                href="#"
                className="secondary-cta"
                onClick={() =>
                  showPopup(
                    "Please, visit www.melbet.com to play and to get amazing rewards. This page is used only for our official promotional purposes."
                  )
                }
              >
                Play on Website
              </a>
            </div>
          </div>
    
          {/* Facilities Slider */}
          <div className="facilities-slider">
            <div className="slider-container">
              <div className="slide">
                <div className="emoji">📈</div>
                <div className="text">
                  <strong>Reliable service provider</strong>
                  <br />
                  Since 2012
                </div>
              </div>
              <div className="slide">
                <div className="emoji">🌍</div>
                <div className="text">
                  <strong>50+ countries</strong>
                  <br />
                  Available worldwide
                </div>
              </div>
              <div className="slide">
                <div className="emoji">👥</div>
                <div className="text">
                  <strong>500,000+ clients</strong>
                  <br />
                  Worldwide user base
                </div>
              </div>
              <div className="slide">
                <div className="emoji" style={{ color: "#000" }}>
                  💰
                </div>
                <div className="text">
                  <strong>Best terms</strong>
                  <br />
                  Competitive activity conditions
                </div>
              </div>
              <div className="slide">
                <div className="emoji" style={{ color: "#000" }}>
                  🔒
                </div>
                <div className="text">
                  <strong>Stable payouts</strong>
                  <br />
                  Guaranteed, timely payments
                </div>
              </div>
            </div>
          </div>
    
          {/* General Terms Section */}
          <div className="section">
            <h2>General Terms</h2>
            <p>
              MLB is owned by Alenesro Ltd (registration number HE 399995) with a
              registered office located at Aristofanous, 219, Mauros Court 140,
              Flat/Office 202, Strovolos, 2038, Nicosia, Cyprus as a Billing Agent.
              We provide secure services and have a wide network across multiple
              countries. All transactions are carried out with the highest
              standards of security and transparency.
            </p>
          </div>
    
          {/* Activity Options Section */}
          <div id="activity-options" className="section activity-options">
            <h2>Activity Options</h2>
            <ul>
              <li>
                <strong>Single Transaction:</strong> A straightforward transaction
                on a single outcome. Winnings are based on the stake multiplied by
                the conditions.
              </li>
              <li>
                <strong>System Transaction:</strong> A combination of multiple
                accumulators with up to 20 events. Increases chances of winning.
              </li>
              <li>
                <strong>Accumulator Transaction:</strong> Transaction on multiple
                unrelated outcomes. A high-risk, high-reward option with multiplied
                conditions.
              </li>
              <li>
                <strong>Asian Handicap:</strong> Offers balanced winning chances,
                popular among experienced clients worldwide.
              </li>
            </ul>
          </div>
    
          {/* Security and Licensing Section */}
          <div className="section">
            <h2>Security and Licensing</h2>
            <p>
              Your safety is our priority. MLB uses advanced SSL and RSA encryption
              to secure all transactions. We enforce mandatory KYC verification to
              protect your account and ensure secure deposits and withdrawals.
            </p>
            <p>
              Licensed and regulated by the Government of Curacao (License No.
              8048/JAZ2020-060), we comply with international standards for online
              activities.
            </p>
          </div>
    
          {/* Footer Section */}
          <div className="footer">
            <p>&copy; 2024 MLB. All rights reserved.</p>
            <a href="#">Privacy Policy</a> | <a href="#" >Terms & Conditions</a> |{" "}
            <a href="/contact-us">Contact Us</a>
          </div>
          <FormModal open={isModalOpen} handleClose={handleCloseModal} />

        </div>
      );
};

export default Homepage;